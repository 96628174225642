import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { fetchPlacements } from 'core/actions/ocapi';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import { addExternalAttributesIfNeeded } from 'core/utils/helpers/externalLink';

import { selectSiteUrl } from 'core/selectors/app';
import { selectIsMobileMQ } from 'core/modules/MediaQueries/selectors';

import {
  selectCreditCardMarketingBannerConfig,
  selectCreditCardMarketingBannerIsLoading,
  selectCreditCardMarketingBannerIsLoaded,
  selectCreditCardMarketingBannerPlacement,
} from './selectors';

import './CreditCardMarketingBanner.scss';

function CreditCardMarketingBanner() {
  const config = useSelector(selectCreditCardMarketingBannerConfig);
  const isLoading = useSelector(selectCreditCardMarketingBannerIsLoading);
  const isLoaded = useSelector(selectCreditCardMarketingBannerIsLoaded);
  const placement = useSelector(selectCreditCardMarketingBannerPlacement);
  const siteUrl = useSelector(selectSiteUrl);
  const isMobileMQ = useSelector(selectIsMobileMQ);

  const {
    clickUrl,
    assets: {
      text, imageAlternateText = '', callToActionText, image, rolloverCopy, shortSummaryText,
    } = {},
  } = placement;
  const dispatch = useDispatch();
  const {
    useButtonView, showCardTerms, getShowCardTip, showCardHeading,
  } = config;

  const showCardTip = getShowCardTip(isMobileMQ);

  useEffect(() => {
    if (!isLoaded && !isLoading) {
      const { api: { params, options } } = config;
      dispatch(fetchPlacements(params, options));
    }
  }, []);

  if (isEmpty(placement)) {
    return null;
  }

  return (
    <div className="mn_creditCardMarketingBannerWrapper">
      <div className="mn_creditCardMarketingBanner">
        <div className="mn_creditCardImageWrap">
          <img src={image} alt={imageAlternateText} />
        </div>
        <div className="mn_creditCardMarketingBannerInsideWrapper">
          <div className="mn_creditCardInfoWrap">
            {showCardTip && <div className="mn_cardTip">{rolloverCopy}</div>}
            {showCardHeading && <h2>{shortSummaryText}</h2>}
            <p dangerouslySetInnerHTML={{ __html: text }} />
            {showCardTerms && <div className="mn_cardTerms">Terms apply.</div>}
          </div>
          <a
            className={`${useButtonView ? 'mn_button mn_secondaryButton' : 'mn_externalLink'}`}
            href={clickUrl}
            {...addExternalAttributesIfNeeded(clickUrl, siteUrl)}
          >
            {callToActionText}
          </a>
        </div>
      </div>
    </div>
  );
}

export default (props) =>
  <ComponentErrorBoundary><CreditCardMarketingBanner {...props} /></ComponentErrorBoundary>;
